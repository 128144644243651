'use strict';

$(document).ready(function () {
  const $catLanding = $('.cat-landing');

  const handleLoadGiftScripts = function () {
    $('<script>').attr('src', $catLanding.data('scripts').giftcard).appendTo('head');
    $('<link>')
      .attr({
        rel: 'stylesheet',
        href: $catLanding.data('styles').giftcard
      })
      .appendTo('head');
  };

  const handleLoadProductScripts = function () {
    $('<script>')
      .attr({
        src: $catLanding.data('scripts').productWishlist,
        id: 'productWishlist'
      })
      .appendTo('head');
    $('<script>')
      .attr({
        src: $catLanding.data('scripts').catLandingEdit,
        id: 'catLandingEdit'
      })
      .appendTo('head');
  };

  const handleEmptyContentPage = function () {
    var $slotContents = $catLanding.find('.home-slots');
    var contentsFound = false;
    $slotContents.each(function() {
      if (!$(this).is(':empty')) {
        contentsFound = true;
        return false;
      }
    });

    if (!contentsFound) {
      var location = $catLanding.find('.redirect-url').data('url');
      window.location.href = location;
    }
  };

  if ($('.content-notfound').length) {
    handleEmptyContentPage();
  }

  if ($('.check-balance').length) {
    handleLoadGiftScripts();
  }
  if ($('.product').length) {
    handleLoadProductScripts();
  } else {
    $('body').one('product:recommendationSliderAdded', function () {
      if (!$('#productWishlist').length && !$('#catLandingEdit').length) {
        handleLoadProductScripts();
      }
    });
  }
});
